import React, {useContext} from "react";
import DOMPurify from "dompurify";
import I18nContext from "../../context/I18nContext";
import {renewCookieConsent} from "../../helpers";

const CookieConsentNeeded = () => {

	const i18n = useContext(I18nContext);

	const handleConsentButtonClicked = (e) => {
		e.preventDefault();
		renewCookieConsent();
	}

	return (
		<div className="cookie-consent-needed">
			<div className="cookie-consent-needed-inner">
				<div
					className="text"
					dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(i18n ? i18n.cookies_video : '')}}
				/>
				<button
					className="cookie-consent-button"
					type="button"
					onClick={handleConsentButtonClicked}
				>
					{ i18n ? i18n.cookies_video_button : '' }
				</button>
			</div>
		</div>
	);
};

export default React.memo(CookieConsentNeeded);