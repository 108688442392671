import React from "react";
import {NavLink} from "react-router-dom";
import {cleanurl} from "../../helpers";

const Blogs = ({ data }) => {

	if(!data) {
		return;
	}

	return (
		<nav className="blogs">
			<ul>
				{
					Object.keys(data).map(key => {

						const to = cleanurl(data[key].url);
						const name = data[key].name;
						const icon = data[key].icon;

						return (
							<li key={key}>
								<NavLink to={ to } title={name}>
									<span className="icon">
										{
											icon ? <img src={icon} alt={name}/> : null
										}
									</span>
									<span className="name">{ name }</span>
								</NavLink>
							</li>
						)
					})
				}
			</ul>
		</nav>
	);
};

export default Blogs;