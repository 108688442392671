import React, {useEffect, useRef} from "react";

const CookieInformationTemplate = () => {

	const ref = useRef();

	useEffect(() => {
		// Div set in index.html
		const cookiecontentCookieinformation = document.getElementById('cookiecontent-cookieinformation');
		if(cookiecontentCookieinformation) {
			ref.current.appendChild(cookiecontentCookieinformation.cloneNode(true));
		}

	}, []);

	return (
		<div className="cookie-information-template">
			<div
				className="cicc-wrapper"
				ref={ref}
			/>
		</div>
	);
};

export default CookieInformationTemplate;