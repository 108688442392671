import React, {useContext} from "react";
import I18nContext from "../../context/I18nContext";

const LoadingAnimation = () => {

	const i18n = useContext(I18nContext);

	return (
		<div className="loading-animation">
			<span className="spinner" />
			<span className="label">{ i18n ? i18n.loading : null }</span>
		</div>
	);
};

export default LoadingAnimation;