import React from 'react';
import {useContext} from 'react';
import BlogContext from "../context/BlogContext";
import {cleanurl} from "../helpers";
import {Link} from "react-router-dom";

const Logo = () => {

	const blog = useContext(BlogContext);
	const url = cleanurl( blog.url );
	const title = `${blog.name} ${blog.description}`;

	return (
		<Link to={url} title={title} className="logo">
			<span className="name">{ blog.name }</span>
			{ blog.description ? <span className="tagline">{ blog.description }</span> : null }
		</Link>
	);
};

export default Logo;