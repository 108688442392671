import React from "react";
import {cleanurl} from "../../helpers";
import {NavLink} from "react-router-dom";

const Image = React.forwardRef(({
	id,
	src,
	alt,
	lazy = true,
	extraClasses = '',
	link = null
}, ref) => {

	const renderLink = () => {
		if(link) {
			const linkParams = {
				to: cleanurl( link.url ),
				title: link.title,
				target: (link.target ? `target="${link.target}" rel="noreferrer nofollow"` : null),
				className: "permalink"
			}
			return <NavLink {...linkParams} />;
		}
		return null;
	}

	const className = `image attachment-${id} ${extraClasses}`;

	return (
		<figure
			ref={ ref }
			className={ className }
		>
			{ renderLink() }
			<img src={src} alt={alt} loading={ lazy ? 'lazy' : 'eager' } />
		</figure>
	);
});

export default React.memo(Image);