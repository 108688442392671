import React from "react";

const PlayButton = ({callback}) => {

	return (
		<button
			type="button"
			className="play-button"
			onClick={ callback }
		>
			<span className="play-button-inner" />
		</button>
	);

};

export default PlayButton;