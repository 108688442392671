import React from "react";

const Footer = () => {

	return (
		<footer id="the-footer">
			<div className="footer-inner" />
		</footer>
	);
};

export default React.memo(Footer);