import React, {useContext, useEffect, useRef, useState} from "react";
import DOMPurify from "dompurify";

import { useNavigate } from 'react-router-dom';

import I18nContext from "../../context/I18nContext";
import {cleanurl} from "../../helpers";

import Splide from '@splidejs/splide';
import '@splidejs/splide/css/core';

const TheContent = ({content, className = ''}) => {

	const [isExpanded, setIsExpanded] = useState(false);

	const i18n = useContext(I18nContext);
	const ref = useRef();
	const navigate = useNavigate();

	useEffect(() => {

		const handleInternalLinkClicked = (e) => {
			e.preventDefault();
			navigate(e.currentTarget.getAttribute('href'));
		}

		const links = ref.current.querySelectorAll('a:not([target="_blank"]):not([href^=mailto]):not([href^=tel]):not([download])');
		if(links) {
			links.forEach((a) => {
				let href = a.getAttribute('href');
				if(href) {
					href = cleanurl(href);
					a.setAttribute('href', href);
					a.removeEventListener('click', handleInternalLinkClicked);
					a.addEventListener('click', handleInternalLinkClicked);
				}
			})
		}
	}, [navigate]);

	useEffect(() => {
		const expandContent = (e) => {
			e.preventDefault();
			setIsExpanded(true);
		};
		const contractContent = (e) => {
			e.preventDefault();
			setIsExpanded(false);
		};

		const readMoreBtn = ref.current.querySelector('.read-more-btn');
		if(readMoreBtn) {
			readMoreBtn.addEventListener('click', expandContent);
		}

		const readLessBtn = ref.current.querySelector('.read-less-btn');
		if(readLessBtn) {
			readLessBtn.addEventListener('click', contractContent);
		}

		return function () {
			if(readMoreBtn) {
				readMoreBtn.removeEventListener('click', expandContent);
			}
			if(readLessBtn) {
				readLessBtn.removeEventListener('click', contractContent);
			}
		}
	}, []);

	useEffect(() => {
		const spliders = ref.current.querySelectorAll('.splide:not(.is-initialized)');
		if(spliders) {
			spliders.forEach((splide) => {
				new Splide( splide, {
					type   : 'loop',
					perPage: 1,
					autoplay: true,
					interval: 3500,
					speed: 650,
				} ).mount();
			})
		}

	}, [navigate]);

	let html = content.replace('[READ_MORE]', `<button class="read-more-btn"><span>${ i18n ? i18n.read_more : 'Læs mere' }</span></button>`);
	html += `<button class="read-less-btn"><span>${ i18n ? i18n.read_less : 'Luk' }</span></button>`;
	html = DOMPurify.sanitize(html, { ALLOWED_ATTR: ['style','target','href','class','id','srcset','width','height','loading','src','alt','download'] });

	return (
		<div
			className={`the-content ${isExpanded ? 'js-expanded' : ''} ${className ? className : ''}`}
			dangerouslySetInnerHTML={{__html: html}}
			ref={ref}
		/>
	);
};

export default React.memo(TheContent);