import {cleanurl} from "../helpers";
import {NavLink, useLocation} from "react-router-dom";

const ChildPages = ({data, heading}) => {

	const location = useLocation();

	return (
		<div id="child-pages">
			{ heading ? <div className="heading">{heading}</div> : null }
			<nav>
				<ul>
					{
						Object.keys(data).map((key, index) => {

							const displayIndex = ( data[key].index ? data[key].index : (index + 1) ).toString();
							const title = data[key].title;
							const to = cleanurl(data[key].permalink);
							const className = `child-page ${ (to === location.pathname ? 'js-active' : '') } num-digits-${displayIndex.length}`;

							return (
								<li key={key} className={className}>
									<NavLink to={ to } title={ title } className="permalink">
										<span className="child-page-inner">
											<span className="index">{ displayIndex }</span>
											<span className="title">{ title }</span>
										</span>
									</NavLink>
								</li>
							)
						})
					}
				</ul>
			</nav>
		</div>
	);
};

export default ChildPages;