import React, {useEffect, useState} from "react";

import { ReactComponent as Arrow } from "../../assets/images/scroll-arrow.svg";

const ScrollArrow = ({callback = null}) => {

	const hideAt = 100;
	const [isHidden, setIsHidden] = useState( window.scrollY > hideAt );

	useEffect(() => {
		const onScroll = e => {
			setIsHidden(window.scrollY > hideAt);
		};
		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return (
		<button
			type="button"
			className={`scroll-arrow ${isHidden ? 'is-hidden' : ''}`}
			onClick={ callback }
		>
			<Arrow />
		</button>
	);

};

export default ScrollArrow;