import React, {useEffect, useState} from "react";
import Image from "./Image";
import Video from "./Video";
import PlayButton from "../parts/PlayButton";
import FullscreenButton from "../parts/FullscreenButton";
import {requestFullscreen} from "../../helpers";

const Animation = ({ xs, md }) => {

	const videoRef = React.createRef();
	const posterRef = React.createRef();

	// Use mobile or desktop data
	const [useDesktopVersion, setUseDesktopVersion] = useState(true);
	const data = ( useDesktopVersion ? md : xs );

	const hasVideo = ( typeof data.src === 'string' && data.src !== '');

	const [videoState, setVideoState] = useState(hasVideo && data.autoplay ? 'playing' : 'idle');

	// Handle changes to window size, so we may toggle between mobile and desktop versions of the <video>
	useEffect(() => {

		const handleMediaQueryChange = (e) => {
			setUseDesktopVersion(e.matches);
		};

		const mediaQuery = window.matchMedia("(min-width: 768px)");
		mediaQuery.addEventListener('change', handleMediaQueryChange);
		setUseDesktopVersion(mediaQuery.matches);

		// Clean up on unmount
		return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
	}, []);

	// Detect fullscreen change
	useEffect(() => {

		const handleFullscreenChange = () => {
			if(document.fullscreenElement) {
				document.body.classList.add('js-fullscreen-enabled');
			}
			else {
				document.body.classList.remove('js-fullscreen-enabled');
			}
		}
		document.addEventListener('fullscreenchange', handleFullscreenChange);

		// Cleanup
		return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
	}, []);

	const handleOnEnded = () => {
		setVideoState('ended');
	}
	const handleOnPlaying = () => {
		setVideoState('playing');
	}
	const handleOnPause = () => {
		setVideoState('paused');
	}
	const handlePlayButtonClicked = () => {
		if(videoRef.current) {
			videoRef.current.play();
		}
	}
	const handleFullscreenButtonClicked = () => {
		if(videoRef.current) {
			requestFullscreen(videoRef.current);
			videoRef.current.play();
		}
		else if(posterRef.current) {
			requestFullscreen(posterRef.current);
		}
	}

	// Build Video props
	let videoProps = {
		src : data.src,
		muted: true,
		playsInline: true,
	};
	if(data.autoplay) {
		videoProps['autoPlay'] = true;
	}
	if(data.loop) {
		videoProps['loop'] = true;
	}
	if(data.controls) {
		videoProps['controls'] = true;
	}

	const poster = data.poster;
	const endFrame = data.endframe;
	const playButton = data.playButton;
	const fullscreenButton = data.fullscreenButton;

	const showPlayButton = ( hasVideo && playButton && videoState !== 'playing' && videoState !== 'paused' );

	const bgColor = data.bgColor;
	const className = `animation state-${videoState} ${ poster ? 'has-poster' : '' }`;

	return (
		<div className={className} style={{backgroundColor:bgColor}}>
			{
				// Show the video
				( videoState !== 'ended' && hasVideo ) || ( hasVideo && showPlayButton )
					? <Video
						ref={videoRef}
						{...videoProps}
						onEnded={handleOnEnded}
						onPlaying={handleOnPlaying}
						onPaused={handleOnPause}
					/>
					: null
			}
			{
				// Show the end frame
				endFrame
					? <Image
						{...data.endframe}
						extraClasses="endframe"
					/>
					: null
			}
			{
				// Show the poster
				poster
					? <Image
						ref={posterRef}
						{...data.poster}
						extraClasses="poster"
					/>
					: null
			}
			{
				// Show the play button
				showPlayButton
					? <PlayButton callback={ handlePlayButtonClicked } />
					: null
			}
			{
				// Show the play button
				fullscreenButton
					? <FullscreenButton callback={ handleFullscreenButtonClicked } />
					: null
			}
		</div>
	);
};

export default React.memo(Animation);