import MenuItem from "./MenuItem";
import {useLocation} from "react-router-dom";

const MenuBlog = ({name, items}) => {

	const location = useLocation();

	if(!items || Object.keys(items).length <= 0) {
		return null;
	}

	return (
		<div  id="menu-blog" className="menu-blog">
			<div className="name">{name}</div>
			<nav>
				<ul className="menu">
				{
					Object.keys(items).map(key => {

						const itemProps = items[key];

						return <MenuItem key={key} {...itemProps} location={location} />
					})
				}
				</ul>
			</nav>
		</div>
	);
};

export default MenuBlog;