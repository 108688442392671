import React, {useContext} from 'react';
import { Helmet } from 'react-helmet';
import BlogContext from "../../context/BlogContext";

const MetaTags = ({tags}) => {

	const blog = useContext(BlogContext);
	const blogId = blog.id;

	return (
		<Helmet>
			<title>{ tags.default.title }</title>

			<link rel="manifest" crossOrigin="use-credentials" href={`/assets/blogs/${ blogId }/manifest.json`} />

			<link rel="shortcut icon" href={`/assets/blogs/${ blogId }/icons/favicon.png`} />
			<link rel="icon" href={`/assets/blogs/${ blogId }/icons/favicon.png`} />
			<link rel="icon" sizes="192x192" href={`/assets/blogs/${ blogId }/icons/192x192.png`} />
			<link rel="apple-touch-icon" sizes="192x192" href={`/assets/blogs/${ blogId }/icons/192x192.png`} />

			<link href={`/assets/blogs/${ blogId }/splashscreens/iphone5_splash.png`} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/iphone6_splash.png`} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/iphoneplus_splash.png`} media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/iphonex_splash.png`} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/iphonexr_splash.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/iphonexsmax_splash.png`} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/ipad_splash.png`} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/ipadpro1_splash.png`} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/ipadpro3_splash.png`} media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
			<link href={`/assets/blogs/${ blogId }/splashscreens/ipadpro2_splash.png`}media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />

			<link rel="canonical" href={ window.location.href } />
			<meta property="og:site_name" content={ tags.sitename } />
			<meta property="og:title" content={ tags.facebook.title } />
			<meta property="og:url" content={ window.location.href } />
			<meta property="og:description" content={ tags.facebook.description } />
			<meta property="og:image" content={ tags.facebook.image } />
			<meta property="og:image:secure_url" content={ tags.facebook.image } />

			<meta name="twitter:title" content={ tags.twitter.title } />
			<meta name="twitter:description" content={ tags.twitter.description } />
			<meta name="twitter:image" content={ tags.twitter.image } />

			<meta name="description" content={ tags.default.description } />
		</Helmet>
	);

};

export default MetaTags;