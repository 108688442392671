import { Swiper, SwiperSlide } from 'swiper/react';
import React, {useState} from "react";

import 'swiper/css';
import Animation from "./Animation";
import Image from "./Image";
import DOMPurify from "dompurify";
import VideoVimeo from "./VideoVimeo";
import VideoYouTube from "./VideoYouTube";
import {Navigation, Pagination} from "swiper/modules";

const Intro = ({data}) => {

	const [videoPlaying, setVideoPlaying] = useState(true);

	if(!data.items) {
		return null;
	}

	const handleVideoPlaying = () => {
		if(data.context) {
			setVideoPlaying(false);
		}
	}
	const handleVideoEnded = () => {
		if(data.context) {
			setVideoPlaying(true);
		}
	}

	const renderSlides = () => {

		return Object.keys(data.items).map(key => {

			const type = data.items[key].type;

			if(type === 'animation') {
				return (
					<SwiperSlide
						key={key}
						className="animation-slide"
					>
						<Animation
							xs={ data.items[key].animation }
							md={ data.items[key].animation_md }
						/>
					</SwiperSlide>
				);
			}
			else if(type === 'image') {
				return (
					<SwiperSlide
						key={key}
						className="image-slide"
					>
						<Image
							src={ data.items[key].src }
							id={ data.items[key].id }
							alt={ data.items[key].alt }
							link={ data.items[key].link }
						/>
					</SwiperSlide>
				);
			}
			else if(type === 'movie') {

				if(data.items[key].vendor === 'vimeo') {
					return (
						<SwiperSlide
							key={key}
							className="video-slide vimeo-slide"
						>
							<VideoVimeo
								videoId={ data.items[key].id }
								poster={ data.items[key].poster }
								onPlaying={ handleVideoPlaying }
								onEnded={ handleVideoEnded }
							/>
						</SwiperSlide>
					);
				}
				else if(data.items[key].vendor === 'youtube') {
					return (
						<SwiperSlide
							key={key}
							className="video-slide youtube-slide"
						>
							<VideoYouTube
								videoId={ data.items[key].id }
								poster={ data.items[key].poster }
								onPlaying={ handleVideoPlaying }
								onEnded={ handleVideoEnded }
							/>
						</SwiperSlide>
					);
				}
			}
			return null;
		});
	};

	const hasAnimationSlide = Object.keys(data.items).some(key => data.items[key].type === 'animation');
	const className = `intro ${!videoPlaying ? 'video-playing' : ''} num-items-${ Object.keys(data.items).length } ${hasAnimationSlide ? 'has-animation-slide' : ''}`

	return (
		<div className={ className }>
			{
				data.context && videoPlaying
					? (
						<aside className="context">
							<h6 className="title" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.context.title)}} />
							<div className="tagline" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.context.tagline)}} />
						</aside>
					)
					: null
			}
			<Swiper
				modules={[Navigation, Pagination]}
				className="slideshow"
				spaceBetween={0}
				slidesPerView={1}
				navigation={ videoPlaying }
				pagination={{ clickable: true }}
			>
				{ renderSlides() }
			</Swiper>
		</div>
	);
};

export default Intro;