import {NavLink} from "react-router-dom";
import {cleanurl} from "../helpers";

const TitleBar = ({data}) => {

	const to = ( data.permalink.url && data.permalink.title ? cleanurl(data.permalink.url) : null );

	return (
		<div id="title-bar" className={ !to ? 'no-permalink' : ''}>
			<div className="title-bar-inner">

				{
					to
						? <NavLink to={ to } state={{useBack:true}} className="permalink">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.4 85.4"><path d="m0 42.4 2.4 2.7 35.4 40.5 6.1-5.3-33.2-37.8L44.9 5.7 39 .2 2.3 39.7 0 42.4z" /></svg>
							<span>{ data.permalink.title }</span>
						</NavLink>
						: null
				}
				<span className="title"><span>{ data.title }</span></span>

			</div>
		</div>
	);
};

export default TitleBar;