import React from 'react';

const MenuOverlayToggler = ({onClicked, className = ''}) => {

	return (
		<button className={`menu-overlay-toggler ${className ? className : ''}`} type="button" onClick={ onClicked }>
			<div className="lines">
				<span className="line" />
				<span className="line" />
			</div>
		</button>
	);

};

export default React.memo(MenuOverlayToggler, () => true);