import {useContext} from 'react';
import BlogContext from '../context/BlogContext';
import MenusContext from "../context/MenusContext";

import Logo from "./Logo";
import MenuPrimary from "./menus/MenuPrimary";
import MenuOverlayToggler from "./menus/MenuOverlayToggler";

const Header = ({toggleMenuOverlay}) => {

	const blog = useContext(BlogContext);
	const menus = useContext(MenusContext);

	if(!blog) {
		return (
			<header id="the-header">
				<div className="header-inner" />
			</header>
		);
	}

	return (
		<header id="the-header">
			<div className="header-inner">
				<Logo />
				<MenuPrimary
					name={menus.primary.name}
					items={ menus.primary.items }
				/>
				<MenuOverlayToggler
					onClicked={ toggleMenuOverlay }
				/>
			</div>
		</header>
	);

}

export default Header;