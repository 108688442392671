import {useQuery} from "@tanstack/react-query";
import {getEndpoint} from "../helpers";
import {useLocation} from "react-router-dom";

const usePost = () => {

	const location = useLocation();

	return useQuery({
		queryKey: ['PostData', location.pathname],
		queryFn: () => {
			const formData = new FormData();
			formData.append('pathname', location.pathname);

			return fetch(getEndpoint('PostData'), {
				method: 'POST',
				body: formData}
			).then(res => res.json());
		}
	});
};

export default usePost;