import React, {useContext} from "react";
import I18nContext from "../../context/I18nContext";

const FullscreenButton = ({callback}) => {

	const i18n = useContext(I18nContext);

	return (
		<button
			type="button"
			className="fullscreen-button"
			onClick={ callback }
		>
			<span className="label">{ i18n ? i18n.fullscreen : '' }</span>
		</button>
	);

};

export default FullscreenButton;