import {useContext} from "react";
import MenusContext from "../../context/MenusContext";
import MenuSport from "./MenuSport";
import MenuAntibulli from "./MenuAntibulli";
import MenuBlog from "./MenuBlog";

const MenuOverlay = () => {

	const menus = useContext(MenusContext);

	const menuBlog = menus.blog;
	const menuSport = menus.sport;
	const menuAntibulli = menus.antibulli;

	return (
		<div id="menu-overlay">
			<div className="menu-overlay-scroll-area">
				<div className="menu-overlay-inner">
					<MenuBlog
						name={menuBlog.name}
						items={menuBlog.items}
					/>
					<MenuSport
						name={menuSport.name}
						items={menuSport.items}
					/>
					<MenuAntibulli
						name={menuAntibulli.name}
						items={menuAntibulli.items}
					/>
				</div>
			</div>
		</div>
	);
};

export default MenuOverlay;