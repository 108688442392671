import React, {useEffect, useRef} from "react";

import Animation from "../content/Animation";
import Blogs from "../content/Blogs";
import TheContent from "../content/TheContent";
import ScrollArrow from "../parts/ScrollArrow";
import {useLocation} from "react-router-dom";

const FrontpageMainContent = ({data}) => {

	const ref = useRef();
	const location = useLocation();

	useEffect( () => {

		if(!ref.current) {
			return;
		}

		if(location.hash === '#om-antibulli') {
			setTimeout(() => {
				if(ref.current) {
					window.scrollTo(0,ref.current.offsetTop + (window.innerWidth >= 768 ? -60 : 0));
				}
			}, 300);
		}
	}, [location] );

	return (
		<>
			<Animation
				xs={ data.animation }
				md={ data.animation_md }
			/>
			<ScrollArrow />
			<Blogs
				data={ data.blogs }
			/>
			<div
				id="om-antibulli-anchor"
				ref={ref}
			/>
			<TheContent
				content={ data.the_content }
				className='frontpage-content'
			/>
		</>
	);
};

export default FrontpageMainContent;