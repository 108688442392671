import React, {useEffect, useState} from 'react';

import {Route, Routes, useLocation} from "react-router-dom";

import I18nContext from './context/I18nContext';
import BlogContext from './context/BlogContext';
import MenusContext from './context/MenusContext';

import Header from './components/Header';
import Footer from './components/Footer';
import MenuOverlay from "./components/menus/MenuOverlay";
import PageLayout from "./components/PageLayout";
import useInitialData from "./hooks/useInitialData";
import MenuOverlayToggler from "./components/menus/MenuOverlayToggler";

const Antibulli = () => {

	const location = useLocation();
	const [isMenuOverlayShown, setIsMenuOverlayShown] = useState(false);
	const [blogId, setBlogId] = useState(0); // Default blogId

	const { isLoading, error, data } = useInitialData(blogId);

	// Close the overlay if we change the location
	useEffect(() => {
		setIsMenuOverlayShown(false);
		window.scrollTo(0, 0);

		if(location.state && location.state.useBack) {
			document.body.classList.add('js-use-prev-transition');
		}
		else {
			document.body.classList.remove('js-use-prev-transition');
		}
	}, [location]);


	const toggleMenuOverlay = () => {
		setIsMenuOverlayShown(isMenuOverlayShown => !isMenuOverlayShown);
	};
	const handlePageChange = (newBlogId, template) => {
		if(newBlogId !== blogId) {
			setBlogId(newBlogId);
		}
		document.body.setAttribute('data-template', template);
		document.body.setAttribute('data-blog', newBlogId);
	}

	const pathname = location.pathname;
	const pathnameArray = pathname ? pathname.replace('/','').split('/') : null;
	const blogPath = Array.isArray(pathnameArray) && pathnameArray.length > 0 ? pathnameArray[0] : null;

	if(isLoading) {
		return (
			<section id="antibulli-app" className={`blog-${blogId} blogpath-${blogPath}`}>
				<Header />
				<main id="page-layout-loading" className="page-layout">
					<div id="title-bar"><div className="title-bar-inner" /></div>
					<div className="page-layout-inner">
						<div className="the-editor-content" />
					</div>
				</main>
				<Footer />
			</section>
		);
	}
	if(error) {
		return 'An error has occurred: ' + error.message;
	}

	const className = `antibulli-app ${ isMenuOverlayShown ? 'js-menu-overlay-revealed' : '' } blog-${blogId} ${location.pathname === '/' ? 'is-home' : ''} blogpath-${blogPath} ${data?.bloginfo?.hasHotjarPopup ? 'has-hotjar-popup' : ''}`;

	return (
		<I18nContext.Provider value={data.i18n}>
			<BlogContext.Provider value={data.bloginfo}>
				<MenusContext.Provider value={data.menus}>

					<section id="antibulli-app" className={ className }>

						<Header
							toggleMenuOverlay={ toggleMenuOverlay }
						/>

						<MenuOverlayToggler
							onClicked={ toggleMenuOverlay }
							className="mobile-toggler"
						/>

						{
							isMenuOverlayShown
								? <MenuOverlay />
								: null
						}

						<Routes>
							<Route path="*" element={<PageLayout onPageChanged={ handlePageChange } />} />
						</Routes>

						<Footer />

					</section>

				</MenusContext.Provider>
			</BlogContext.Provider>
		</I18nContext.Provider>
	);
}

export default Antibulli;