import {useEffect} from "react";
import { QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {BrowserRouter} from 'react-router-dom';
import Antibulli from './Antibulli';
import "./assets/scss/App.scss";

// Create a client
const queryClient = new QueryClient()

const App = () => {

	//
	// Manage vh unit
	useEffect(() => {
		const setAppHeights = () => {
			const vh = window.innerHeight * .01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
			document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
		};
		window.addEventListener('resize', setAppHeights);
		setAppHeights();

		return () => window.removeEventListener('resize', setAppHeights);
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Antibulli />
			</BrowserRouter>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);

}
export default App;