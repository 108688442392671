import MenuSportItem from "./MenuSportItem";
import {useContext} from "react";
import BlogContext from "../../context/BlogContext";

const MenuSport = ({name, items}) => {

	const blog = useContext(BlogContext);

	return (
		<div id="menu-sport" className="menu-sport">
			<div className="name">{name}</div>
			<nav>
				<ul className="menu">
				{
					Object.keys(items).map(key => {

						const itemProps = items[key];

						if(itemProps.blogId === blog.id) {
							return null;
						}


						return <MenuSportItem key={key} {...itemProps} />
					})
				}
				</ul>
			</nav>
		</div>
	);

};

export default MenuSport;