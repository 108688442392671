import React from "react";

const TitleArea = ({data}) => {

	const index = data.index;
	const title = data.the_title;

	const className = `title-area ${!index ? 'no-index' : ''}`;

	return (
		<div className={className}>
			<div className="title-area-inner">
				{
					index
						? <div className={`index num-digits-${index.toString().length}`}>{ index }</div>
						: null
				}
				<h1 className="title">{ title }</h1>
			</div>
		</div>
	);
};

export default React.memo(TitleArea, () => {
	return true;
});