import React from "react";

import Animation from "../content/Animation";
import TheContent from "../content/TheContent";

const FrontpageSportContent = ({data}) => {

	return (
		<>
			<Animation
				xs={ data.animation }
				md={ data.animation_md }
			/>
			<TheContent
				content={ data.the_content }
				className='frontpage-content frontpage-content-sport'
			/>
		</>
	);
};

export default FrontpageSportContent;