import React, {useState, useEffect} from "react";
import Image from "./Image";
import PlayButton from "../parts/PlayButton";
import YouTube from "react-youtube";
import CookieConsentNeeded from "../parts/CookieConsentNeeded";
import {hasCookieConsentMarketing} from "../../helpers";

const VideoYouTube = ({
	videoId,
	poster = null,
	autoPlay = false,
	onPlaying=null,
	onEnded=null,
}) => {

	const [embedVideo, setEmbedVideo] = useState(autoPlay || !poster);
	const [cookiesAccepted, setCookiesAccepted] = useState(hasCookieConsentMarketing());

	useEffect(() => {

		const handleCookieInformationConsentGiven = () => {
			if(hasCookieConsentMarketing()) {
				setCookiesAccepted(true);
			}
		};

		window.addEventListener('CookieInformationConsentGiven', handleCookieInformationConsentGiven);

		return () => window.removeEventListener('CookieInformationConsentGiven', handleCookieInformationConsentGiven);

	}, []);

	const handlePlayButtonClicked = () => {
		setEmbedVideo(true);
		if(onPlaying) {
			onPlaying();
		}
	}
	const handleVideoEnded = () => {
		setEmbedVideo(false);
		if(onEnded) {
			onEnded();
		}
	}

	return (
		<div className="youtube-video">
			{
				poster && !embedVideo
					? <Image
						{...poster}
						extraClasses="poster"
					/>
					: null
			}
			{
				embedVideo && cookiesAccepted
					? <YouTube
						videoId={ videoId }
						onEnd={ handleVideoEnded }
						opts={{
							playerVars: {
								autoplay: true,
						}} }
					/>
					: ( embedVideo && !cookiesAccepted
							? <CookieConsentNeeded />
							: null
					)
			}
			{
				// Show the play button
				!embedVideo
					? <PlayButton callback={ handlePlayButtonClicked } />
					: null
			}
		</div>
	);
};

export default VideoYouTube;