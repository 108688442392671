export function baseurl() {
	return 'https://api.antibulli.dk/';
	//return 'https://api.antibulli.lokal/';
}
export function cleanurl(url) {
	return url.replace(baseurl(), '/');
}
export function getEndpoint(endpoint) {
	const endpoints	= {
		InitialData: `${ baseurl() }wp-json/antibulli19/v1/initial-data`,
		PostData: `${ baseurl() }wp-json/antibulli19/v1/post-data`,
	};
	return endpoints[endpoint];
}
export function requestFullscreen(element) {
	if(element.requestFullscreen) {
		element.requestFullscreen();
	}
	else if(element.mozRequestFullScreen) {
		element.mozRequestFullScreen();
	}
	else if(element.webkitRequestFullscreen) {
		element.webkitRequestFullscreen();
	}
	else if(element.webkitEnterFullScreen) {
		element.webkitEnterFullScreen(); // iPad
	}
}

//
// Cookie consent
export function hasCookieConsentMarketing() {
	if(window.CookieInformation) {
		return window.CookieInformation.getConsentGivenFor('cookie_cat_marketing');
	}
	return false;
}
export function hasCookieConsentStatistics() {
	if(window.CookieInformation) {
		return window.CookieInformation.getConsentGivenFor('cookie_cat_statistic');
	}
	return false;
}
export function hasCookieConsentFunctional() {
	if(window.CookieInformation) {
		return window.CookieInformation.getConsentGivenFor('cookie_cat_functional');
	}
	return false;
}
export function renewCookieConsent() {
	if(window.CookieInformation) {
		window.CookieInformation.renew();
	}
}

export function isWebApp() {
	return window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;
}