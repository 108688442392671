import MenuItem from "./MenuItem";
import {useLocation} from "react-router-dom";

const MenuPrimary = ({name, items}) => {

	const location = useLocation();

	return (
		<nav id="menu-primary" className="menu-primary">
			<ul className="menu">
			{
				Object.keys(items).map(key => {

					const itemProps = items[key];

					return <MenuItem key={key} {...itemProps} location={location} />
				})
			}
			</ul>
		</nav>
	);
};

export default MenuPrimary;