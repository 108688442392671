import React from "react";

const Video = React.forwardRef(({
	src,
	autoPlay = false,
	controls = false,
	muted = true,
	playsInline = true,
	loop = false,
	extraClasses = '',
	onEnded=null,
	onPlaying=null,
	onPaused=null,
}, ref) => {

	let videoProps = {
		src : src,
		muted: muted,
		playsInline: playsInline,
	};
	if(autoPlay) {
		videoProps['autoPlay'] = true;
	}
	if(loop) {
		videoProps['loop'] = true;
	}
	if(controls) {
		videoProps['controls'] = true;
	}

	const className = `video ${extraClasses}`;

	return (
		<video
			ref={ ref }
			{...videoProps}
			className={className}
			onEnded={ onEnded }
			onPause={ onPaused }
			onPlaying={ onPlaying }
		 />
	);

});

// TODO: Memo and forwardRef doesn't seem to work great together. Memo is ignored?
export default React.memo(Video, (prevProps, nextProps) => {
	return prevProps.src === nextProps.src
		&& prevProps.autoPlay === nextProps.autoPlay
		&& prevProps.controls === nextProps.controls
		&& prevProps.muted === nextProps.muted
		&& prevProps.playsInline === nextProps.playsInline
		&& prevProps.loop === nextProps.loop;
});