import TheContent from "../content/TheContent";
import Intro from "../content/Intro";
import TitleArea from "../content/TitleArea";

const DefaultContent = ({data}) => {

	return (
		<>
			{
				data.intro
					? <Intro
						data={ data.intro }
					/>
					: null
			}
			{
				data.title_area
					? <TitleArea
						data={ data.title_area }
					/>
					: null
			}
			<TheContent
				content={ data.the_content }
			/>
		</>
	);

}

export default DefaultContent;