import React, {useContext} from "react";
import I18nContext from "../../context/I18nContext";

const PDFDownload = ({src, name = ''}) => {

	const i18n = useContext(I18nContext);
	const fileName = name ? name : src.replace(/^.*[\\/]/, '');

	return (
		<div className="pdf-download">
			<a href={src} download={fileName} target="_blank" rel="noopener noreferrer">
				<span className="label">{ i18n ? i18n.download_pdf : 'Download' }</span>
			</a>
		</div>
	);
};

export default React.memo(PDFDownload, () => {
	return true;
});