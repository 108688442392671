import React from 'react';

import {NavLink} from "react-router-dom";
import {cleanurl} from "../../helpers";

import { ReactComponent as IconHome } from "../../assets/images/menus/icon-home.svg";
import { ReactComponent as IconHandball } from "../../assets/images/menus/icon-handball.svg";
import { ReactComponent as IconHandballGoal } from "../../assets/images/menus/icon-handball-goal.svg";
import { ReactComponent as IconFootball } from "../../assets/images/menus/icon-football.svg";
import { ReactComponent as IconFootballGoal } from "../../assets/images/menus/icon-football-goal.svg";
import { ReactComponent as IconSwimming } from "../../assets/images/menus/icon-swimming.svg";
import { ReactComponent as IconCoach } from "../../assets/images/menus/icon-coach.svg";
import { ReactComponent as IconHighFive } from "../../assets/images/menus/icon-high-five.svg";
import { ReactComponent as IconSwimmingLane } from "../../assets/images/menus/icon-swimming-lane.svg";
import { ReactComponent as IconTennis } from "../../assets/images/menus/icon-tennis.svg";
import { ReactComponent as IconTennisRacket } from "../../assets/images/menus/icon-tennis-racket.svg";
import { ReactComponent as IconRidning } from "../../assets/images/menus/icon-ridning.svg";
import { ReactComponent as IconRidningHelmet } from "../../assets/images/menus/icon-ridning-helmet.svg";
import { ReactComponent as IconGymnastics } from "../../assets/images/menus/icon-gymnastics.svg";
import { ReactComponent as IconGymnasticsMat } from "../../assets/images/menus/icon-gymnastics-mat.svg";
import { ReactComponent as IconGymnasticsRings } from "../../assets/images/menus/icon-gymnastics-rings.svg";
import { ReactComponent as IconGymnasticsHands } from "../../assets/images/menus/icon-gymnastics-handss-kopi.svg";
import { ReactComponent as IconBadminton } from "../../assets/images/menus/icon-badminton.svg";
import { ReactComponent as IconBadmintonRacket } from "../../assets/images/menus/icon-badminton-racket.svg";

const MenuItem = (props) => {

	const { title, ID, url, classes, location } = { ...props }

	const to = cleanurl(url);
	const isActive = ( (to === location.pathname) || (!classes.includes('menu-home') && location.pathname.includes(to)) );
	const className = `menu-item ${ classes.join(' ') } ${ isActive ? 'js-active' : '' }`;

	let icon = null;
	if(Array.isArray(classes)) {
		if(classes.includes('icon-home')) {
			icon = <IconHome />;
		}
		else if(classes.includes('icon-coach')) {
			icon = <IconCoach />;
		}
		else if(classes.includes('icon-high-five')) {
			icon = <IconHighFive />;
		}
		else if(classes.includes('icon-handball')) {
			icon = <IconHandball />;
		}
		else if(classes.includes('icon-handball-goal')) {
			icon = <IconHandballGoal />;
		}
		else if(classes.includes('icon-football')) {
			icon = <IconFootball />;
		}
		else if(classes.includes('icon-football-goal')) {
			icon = <IconFootballGoal />;
		}
		else if(classes.includes('icon-swimming')) {
			icon = <IconSwimming />;
		}
		else if(classes.includes('icon-swimming-lane')) {
			icon = <IconSwimmingLane />;
		}
		else if(classes.includes('icon-tennis')) {
			icon = <IconTennis />;
		}
		else if(classes.includes('icon-tennis-racket')) {
			icon = <IconTennisRacket />;
		}
		else if(classes.includes('icon-ridning')) {
			icon = <IconRidning />;
		}
		else if(classes.includes('icon-ridning-helmet')) {
			icon = <IconRidningHelmet />;
		}
		else if(classes.includes('icon-gymnastics')) {
			icon = <IconGymnastics />;
		}
		else if(classes.includes('icon-gymnastics-mat')) {
			icon = <IconGymnasticsMat />;
		}
		else if(classes.includes('icon-gymnastics-rings')) {
			icon = <IconGymnasticsRings />;
		}
		else if(classes.includes('icon-gymnastics-hands')) {
			icon = <IconGymnasticsHands />;
		}
		else if(classes.includes('icon-badminton')) {
			icon = <IconBadminton />;
		}
		else if(classes.includes('icon-badminton-racket')) {
			icon = <IconBadmintonRacket />;
		}
	}

	return (
		<li className={className} id={`menu-item-${ID}`}>
			<NavLink to={to}>
				<span className="icon">{icon}</span>
				<span className="title">{title}</span>
			</NavLink>
		</li>
	);
};

export default MenuItem;