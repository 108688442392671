import React from 'react';

import {NavLink} from "react-router-dom";
import {cleanurl} from "../../helpers";

const MenuSportItem = (props) => {

	const { title, icon, ID, url, classes } = { ...props }
	const to = cleanurl(url);

	const className = `menu-sport-item ${ classes.join(' ') } }`;

	return (
		<li
			className={ className }
			id={ `menu-sport-item-${ID}` }
		>
			<NavLink to={ to }>
				<span className="icon">
					{
						icon ? <img src={icon} alt={title}/> : null
					}
				</span>
				<span className="title">{ title }</span>
			</NavLink>
		</li>
	);
};

export default MenuSportItem;