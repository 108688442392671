import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';

// PRODUCTION ENVIRONMENT ONLY
if('production' === process.env.NODE_ENV) {
	// Remove console.log
	//console.log = function(){};

	// Force https
	if(window.location.protocol !== 'https:') {
		window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
	}
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);