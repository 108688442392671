import Intro from "../content/Intro";
import TitleArea from "../content/TitleArea";
import PDFDownload from "../content/PDFDownload";
import TheContent from "../content/TheContent";

const ExerciseContent = ({data}) => {

	return (
		<>
			{
				data.intro
					? <Intro
						data={ data.intro }
					/>
					: null
			}

			<TitleArea
				data={ data.title_area }
			/>

			{
				data.pdf_download
					? <PDFDownload src={data.pdf_download} />
					: null
			}

			<TheContent
				content={ data.the_content }
			/>
		</>
	);

}

export default ExerciseContent;