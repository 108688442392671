import {useQuery} from "@tanstack/react-query";
import {getEndpoint} from "../helpers";
import {useLocation} from "react-router-dom";

const useInitialData = (blogId) => {

	const location = useLocation();

	return useQuery({
		queryKey: ['InitialData_Blog', blogId],
		queryFn: () => {
			const formData = new FormData();
			formData.append('blog_id', blogId);
			formData.append('pathname', location.pathname);

			return fetch(getEndpoint('InitialData'), {
				method: 'POST',
				body: formData
			}).then(res => res.json());
		}
	});
};

export default useInitialData;